import React, { useEffect, useRef, useState } from 'react';
import eclipse from '../../../assets/images/elips.webp';
import { PositionsStore } from '../../positions/stores/PositionsStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ImageComponent from "../../utils/ImageComponent";

import './home.css';

const HomePageSearchForm = () => {
    const target = useRef( null );
    const history = useHistory();
    const [locationType, setLocationType] = useState( '' );
    const [locationResult, setLocationResult] = useState( [] );
    const position = PositionsStore.useState( state => state );
    const [location, setLocation] = useState( position.location || "" );
    const [typingLocation, setTypingLocation] = useState( false );
    const [isMobile, setIsMobile] = useState( false );

    useEffect( () => {
        // Check if the screen width is smaller than 768px (typical mobile devices)
        const checkIsMobile = () => {
            setIsMobile( window.innerWidth <= 768 );
        };
  
        // Add a resize event listener to update the 'isMobile' state
        window.addEventListener( "resize", checkIsMobile );
  
        // Initial check
        checkIsMobile();
  
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener( "resize", checkIsMobile );
        };
    }, [] );

    const handleSearch = async () => {
        const queryParams = `?search=${position.job_position}&location=${position.location}`;
        history.push( `careers${queryParams}` );
    };

    const checkLocationType = async ( value ) => {
        if ( value.length < 3 ) {
            setLocationResult( [] );
            return;
        }
        try {

            const response = await fetch(
                `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent( value )}&class=${encodeURIComponent( "boundary" )}&addressdetails=1`
            );
            const data = await response.json();
            console.log( data );
            if ( data.length > 0 ) {

                const element = data[0].display_name;
                const wordCount = element.split( "," ).length;

                if ( wordCount === 1 ) {
                    setLocationType( 'country' );
                } else if ( wordCount === 2 ) {
                    setLocationType( 'state' );
                } else if ( !isNaN( location ) ) {
                    setLocationType( 'zipcode' );
                }
                else {
                    setLocationType( 'city' );
                }

                setLocationResult( data );
               
            } else {
                setLocationType( 'city' );
            }
        } catch ( error ) {
            console.error( 'Error:', error );
        }
    };

    function handleChange( e ) {
        const { name, value } = e.target;
        PositionsStore.update( s => {
            s[name] = value;
        } );
    }

    function handleLocChange( e ) {
        setTypingLocation( true );
        setLocation( e.target.value );
    }

    const handleClick = ( item ) => {
        const { lat, lon, address } = item;
        PositionsStore.update( ( s ) => {
            s.longitude = lon;
            s.latitude = lat;
            s.city = address.city ? address.city : "";
            s.state = address.state ? address.state : "";
            s.country = address.country ? address.country : "";
            s.location = item.display_name;
            s.zipcode = item.address.postcode;
        } );
        setLocationResult( [] );
        setTypingLocation( false );
    };

    useEffect( () => {
        const delay = 500; // Debounce delay in milliseconds
        const timerId = setTimeout( () => {
            // Perform search logic here
            checkLocationType( location );
        }, delay );

        return () => {
            clearTimeout( timerId ); // Clear the timer on component unmount or when searchQuery changes
        };
    }, [location] );

    return (
        <div className='background-form'>
            <img src={eclipse} alt='eclipse' className='form-img' />
            <div className="contain">
                <form className='flex my-form w-100'>
                    <div className="input-group" style={{ height: "36px", width: "100%" }}>
                        <div className="input-group-prepend">
                            <span className="input-group-text" style={{ backgroundColor: "white", borderRight: "none" }}> <FontAwesomeIcon icon={faSearch} alt="logo" /></span>
                        </div>
                        <div style={{ width: "43%" }}>
                            <input className="form-control py-2 rounded-0 border-right-0 mobile_form" disabled={position.advanceFilter}
                                onKeyDown={
                                    ( key ) => {
                                        if ( key.keyCode === 13 ) {
                                            handleSearch().then( null );
                                        }
                                    }
                                }
                                ref={target}
                                margin="normal"
                                placeholder={"Start your job search"}
                                name="job_position"
                                value={position.job_position}
                                type="search"
                                onChange={handleChange} />
                        </div>
                        <div className='d-flex flex-column location'>
                            <input className="form-control py-2 border rounded-0"
                                type="search"
                                margin="normal"
                                placeholder={isMobile ? "Location" : "City, state, country, zipcode"}
                                name="city"
                                value={location}
                                onChange={handleLocChange}
                            />
                            {( location.length > 0 && typingLocation ) && locationResult && (
                                <div style={{ position: "relative" }}>
                                    <ul
                                        className="location-list"
                                        style={{
                                            position: "absolute",
                                            // top: "100%",
                                            // left: "200px",
                                            // right: "-3.5rem",
                                            backgroundColor: "white",
                                            // Set a maximum width for the list
                                            minWidth: "100%", // Set a minimum width for the list
                                            maxHeight: "310px", // Set a maximum height for the list
                                            overflow: "auto",
                                            zIndex: 100
                                        }}
                                    >
                                        {locationResult.map( ( item ) => (
                                            <li
                                                className="p-2 isCursor"
                                                key={item.geonameId}
                                                onClick={() => handleClick( item, locationType )}
                                            >
                                                {item.display_name}
                                            </li>
                                        ) )}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className="input-group-append">
                            <div className="input-group-text" id="btnGroupAddon2" style={{ borderTopRightRadius: '5px', borderBottomRightRadius: '5px', cursor: 'pointer', marginLeft: 0,color:'black' }} disabled={position.advanceFilter}
                                onClick={handleSearch}>
                                <FontAwesomeIcon icon={faSearch} className="mr-2" />
                                {isMobile?"": "Search"}
                            </div>

                        </div>
                    </div>
                </form>
            </div>

            {/* {position.advanceFilter ? <AdvancedSearch makeSearch={()=>search()} />:""} */}
        </div>
    );
};

export default HomePageSearchForm;