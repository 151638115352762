import { MuiThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import util from '../../utils/miniUtils';

const ParentTicketSearch = ( { value, isChildTicket, setData, workloadSimple } ) => {
    const [displayValue, setDisplayValue] = useState( "" );
    const [show, setShow] = useState( false );
    
    const handleClose = () => setShow( false );

    const handleClick = () => {
        setShow( true );
    };

    
    const handleSelect = ( value ) => {
        let selectedWorkload = workloadSimple.filter( item => item.id === value );
        setDisplayValue( selectedWorkload[0].id + ". " + selectedWorkload[0].short_description );
        setData( ( prev ) => {
            return {
                ...prev,
                parent_ticket_id: value
            };
        } );
        handleClose();
    };

  

    useEffect( () => {
        if( workloadSimple.length > 0 && value ) {
            const currentValue = workloadSimple.find( item => item.id === value );
            setDisplayValue( currentValue.id + ". " + currentValue.short_description );
        }
    },[workloadSimple, value] );

    useEffect( () => {
        if( !value ) {
            setDisplayValue( '' );
        }
    }, [value] );

    return <>
        <>
            <WorkloadTableModal show={show} handleClose={handleClose} data={workloadSimple} handleSelect={handleSelect} />
            <div className={`w-100${displayValue ? ' parentWorkload' : ''}`} onClick={handleClick}>
                {isChildTicket ? ( displayValue ? <span onClick={handleClick}>{displayValue}</span>
                    : <button className="btn btn-outline-primary w-100">Select Parent Ticket</button> ) 
                    : ""}
            </div>
        </>
    </>;
};

export default ParentTicketSearch;

const WorkloadTableModal = ( { show, handleClose, data, handleSelect } ) => {
    return <>
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Body>
                <MuiThemeProvider theme={util.defaultTableTheme}>
                    <MaterialTable
                        columns={[
                            { title: "Id", field: "id", cellStyle: { textAlign: "left" } },
                            { title: "Title", field: "short_description", cellStyle: { textWrap: "nowrap" } },
                            { 
                                title: "",
                                field: "id",
                                render: value => <button className='btn btn-sm btn-outline-primary' onClick={() => handleSelect( value.id )}>Select</button>,
                                cellStyle: { maxWidth: "20%", textAlign: "right" }
                            }
                        ]}
                        data={data}
                        options={{
                            searchFieldAlignment: "left",
                            selection: false,
                            showTitle: false,
                            pageSize: 20,
                            pageSizeOptions: [20, 50, 100],
                            headerStyle:{ position:'sticky',top:0 }
                        }}
                    />
                </MuiThemeProvider>
            </Modal.Body>
        </Modal>
    </>;
};