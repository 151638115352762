import React, { useEffect, useState } from "react";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBuilding, faSearch, faSignOutAlt, faTimes, faTrash, faUser, faUserCheck, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { PositionsStore } from "../../../positions/stores/PositionsStore";
import util from "../../../utils/miniUtils";
import ImageComponent from "../../../utils/ImageComponent";

import { Dropdown } from "react-bootstrap";
import Register from "../../../positions/modals/Register";
import Login from "../../../positions/modals/Login";
import TalentNotification from "../../../positions/navbar/TalentNotification";
import FillProfile from "../../../positions/modals/FillProfileNew";
import ProfilePopup from "../../../shared/profile_component/ProfilePopup";
import { useLocation } from "react-router-dom";
import ImageWithFallback from "../../../shared/ImageWithFallback";
// import { Link } from "react-router-dom"

const Header = ( { handleShowModal } ) => {
    const [navList, setNavList] = useState( false );
    const position = PositionsStore.useState( state => state );
    const [show, setShow] = useState( false );
    const [showModal, setShowModal] = useState( false );
    const location = useLocation();
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [appInfo, setAppInfo] = useState({ title: "", logo: "", small_logo: "" });
    // Handle display reCaptcha
    useEffect( () => {
        setTimeout( () => {
            setShow( false );
        }, 2500 );
    }, [show] );

    useEffect( () => {

    }, [position.isLogged]);

    useEffect(() => {
        getAppInfo();
    }, []);

    // Handle changes in search bar or advanced search

    const deleteAccount = async () => {
        try {
            const request = await util.axios.get( `/delete_data` );
            const { error, msg } = request.data;
            util.logOut();
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const go_mainPage = () => {
        const pageUrl = '/';
        window.history.pushState( '', '', pageUrl );
        window.location.reload();
    };

    const getAppInfo = async () => {
        try {
            const request = await util.axios.get('/app_resources/settings/app_info');
            const { msg, error, data } = request.data;
            if (error) throw msg;
            setAppInfo(data);
        } catch (error) {
            util.handleError(error);
        }
    };

    { util.LOADING_SCREEN(position.loading); }

    return (
        <>
            <header>
                <div className='ej_container flex'>
                    <div className='logo isCursor' onClick={go_mainPage}>
                        {appInfo.logo ? <ImageWithFallback
                            src={`${util.baseUrl()}${util.AWSURL()}/global/images/${appInfo.logo}`}
                            alt={appInfo.title ? appInfo.title : "eJRekruit"}
                            fallbackSrc={'./images/demo_logo.svg'} /> :
                            <img src='./images/demo_logo.svg' alt='ejRekruit' />}
                    </div>
                    <div className='nav'>
                        <ul
                        // className={navList ? "small" : "flex"}
                        >
                            {position.isLogged ?
                                <>
                                    <div className="align-items-center d-flex">
                                        {util.user?.name ? <TalentNotification /> : ""}
                                        <Dropdown>
                                            <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ minWidth: "12rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                {util.customDropDownName( util.user?.name )}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {( window.location.href ).split( '/' ).pop().split( "?" )[0] !== 'careers' ?
                                                    <Dropdown.Item href="/careers">
                                                        <FontAwesomeIcon color="#aeaeae" icon={faSearch} className="mr-1" />
                                                        Search Jobs
                                                    </Dropdown.Item> : ""}

                                                {( window.location.href ).split( '/' ).pop().split( "?" )[0] !== 'appliedJobs' ? <Dropdown.Item href="/appliedJobs" >
                                                    <FontAwesomeIcon color="#aeaeae" icon={faBuilding} className="mr-1" />
                                                    Applied Jobs
                                                </Dropdown.Item> : ""}
                                                {( util.user && util.user.permissions && util.user.permissions.talent && !util.user.permissions.employee ) &&
                                                    <>
                                                        <Dropdown.Item href={`/profile`}>
                                                            <FontAwesomeIcon color="#aeaeae" icon={faUserCircle} className="mr-1" />
                                                            Profile
                                                        </Dropdown.Item>
                                                        {location.pathname === '/profile' && (
                                                            <Dropdown.Item onClick={handleShow}>
                                                                <FontAwesomeIcon color="#aeaeae" icon={faUserCheck} className="mr-1" />
                                                                Complete Your Profile
                                                            </Dropdown.Item>
                                                        )}</>}

                                                <util.AvailableRole role="Recruiter" selectedRole="recruiter" />
                                                {util.user.role === 2 ? null : <util.AvailableRole role="Talent" selectedRole="talent" />}
                                                <util.AvailableRole role="Client" selectedRole="client" />
                                                <util.AvailableRole role="Partner" selectedRole="partner" />
                                                <util.AvailableRole role="Evaluation" selectedRole="evaluator" />
                                                <util.AvailableRole role="Employee" selectedRole="employee" />
                                                <util.AvailableRole role="Recruitment Manager" selectedRole="recruiter_manager" />
                                                <Dropdown.Item>
                                                    <div onClick={() => deleteAccount()}><FontAwesomeIcon color="#ff7272" icon={faTrash} className="mr-2" /> Delete Account</div>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <div onClick={() => util.logOut()}><FontAwesomeIcon color="#ff7272" icon={faSignOutAlt} className="mr-2" /> Logout</div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </>

                                :
                                <button
                                    onClick={() => {
                                        PositionsStore.update( s => {
                                            s.loginModal = true;
                                        } );
                                    }}
                                    className="btn btn-sm btn-primary align-self-center align-content-end" style={{ width: "120px", height: "30px" }}>
                                    <FontAwesomeIcon icon={faUser} className="mr-2" />
                                    Login
                                </button>
                            }


                        </ul>
                    </div>

                </div>
            </header>
            {position.createAccount ? <Register /> : null}
            {position.loginModal ? <Login /> : null}
            {position.profileProcess ? <FillProfile /> : ""}
            {/* {position.advanceFilter ? <AdvancedSearch makeSearch={()=>search()} />:""} */}
            {showModal && <ProfilePopup show={showModal} handleClose={handleClose} />}
        </>
    );
};

export default Header;